<template>
    <v-dialog v-model='dialog' persistent max-width='600px'>
        <v-card>
            <v-card-title>
                <span class='headline'>Language manager</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols='12'>
                            <v-text-field label='it_IT' required v-model='lang.it_IT'>
                            </v-text-field>
                        </v-col>

                        <v-col cols='12'>
                            <v-text-field label='en_GB' required v-model='lang.en_GB'>
                            </v-text-field>
                        </v-col>

                        <v-col cols='12'>
                            <v-text-field label='de_DE' required v-model='lang.de_DE'>
                            </v-text-field>
                        </v-col>

                        <v-col cols='12'>
                            <v-text-field label='fr_FR' required v-model='lang.fr_FR'>
                            </v-text-field>
                        </v-col>

                        <v-col cols='12'>
                            <v-text-field label='es_ES' required v-model='lang.es_ES'>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color='success' @click='saveDetail()'>Save</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>
          
<script>
// const config = require('../config');

export default {
    name: 'TranslateString',

    props: {
        'ilang': { type: Object, default: () => { } },
    },

    data: function () {
        return {
            dialog: true,
            lang: {},
        }
    },

    methods: {
        saveDetail() {
            this.$emit('newLang', this.lang);
            this.$emit('closed', true);
        },
    },

    created: function () {
        this.lang = this.ilang;
    },
}
</script>
