<!-- eslint-disable no-undef -->
<template>
  <v-app>
    <notifications group="main" position="center" />

    <div v-if="!login">
      <v-row>
        <v-col cols="2" class="pb-0 mb-0">
          <v-card height="100%" width="100%">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  ContentManager
                </v-list-item-title>
                <v-list-item-subtitle> Admin panel </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense nav>
              <v-list-item @click="
                $router.push({ name: 'userManagement' }).catch(() => { })
              ">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>User management</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="
                $router.push({ name: 'userAuthorization' }).catch(() => { })
              ">
                <v-list-item-icon>
                  <v-icon>mdi-lock</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>User authorization</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="
                $router.push({ name: 'authConfiguration' }).catch(() => { })
              ">
                <v-list-item-icon>
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Auth configuration</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="
                $router.push({ name: 'containerManagement' }).catch(() => { })
              ">
                <v-list-item-icon>
                  <v-icon>mdi-archive</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Container management</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="
                $router.push({ name: 'importManagement' }).catch(() => { })
              ">
                <v-list-item-icon>
                  <v-icon>mdi-import</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Import management</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="
                $router.push({ name: 'filterManagement' }).catch(() => { })
              ">
                <v-list-item-icon>
                  <v-icon>mdi-filter</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Filter management</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="logout()">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>

        <v-col class="pb-0 mb-0">
          <v-sheet>
            <router-view />
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <LoginPage v-if="login" @loggedIn="loginSuccessful()"></LoginPage>
  </v-app>
</template>

<script>
//const config = require("./config");
import LoginPage from "./components/LoginPage.vue";

export default {
  name: "App",
  components: { LoginPage },
  data: () => ({
    login: false,
  }),
  mounted() {
    if (
      // eslint-disable-next-line no-undef
      localStorage.getItem(config.jwtStorageKey) && localStorage.getItem(config.jwtStorageKey).length > 0
    ) {
      this.axios
        .post(`/mng/read/users`, {})
        .then(() => {
          this.login = false;
        }).catch((err) => {
          this.$router.push({ name: "homePage" }).catch(() => { });
          this.login = true;

          console.error(err)
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error ' + err.response.data.msg[0].msgId,
            text: err.response.data.msg[0].msgTxt
          })
        });
    } else {
      this.$router.push({ name: "homePage" }).catch(() => { });
      this.login = true;
    }

    // console.log('Login: ', this.login)
  },
  updated: function () {
    if (
      // eslint-disable-next-line no-undef
      localStorage.getItem(config.jwtStorageKey) === "expired" || !localStorage.getItem(config.jwtStorageKey) || localStorage.getItem(config.jwtStorageKey).length == 0
    ) {
      this.login = true;
    }
  },
  methods: {
    loginSuccessful() {
      this.login = false;
    },
    logout() {
      // eslint-disable-next-line no-undef
      localStorage.setItem(config.jwtStorageKey, null);
      this.$router.push({ name: "homePage" }).catch(() => { });
      this.login = true;
    },
  },
};
</script>
