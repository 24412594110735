<template>
  <v-app>

    <v-container>
      <h2>Home</h2>
    </v-container>

  </v-app>
</template>

<script>

export default {
  name: 'HomePage',
};

</script>