var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',[_c('h2',[_vm._v("Auth configurations list")]),_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"label":"Search container","required":""},on:{"input":function($event){return _vm.getAuthConfigList()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-2 mb-2",staticStyle:{"float":"right"},attrs:{"color":"success"},on:{"click":function($event){return _vm.addAuthConfig()}}},[_c('v-icon',[_vm._v(" mdi-plus-box ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":[
            {
              text: 'Auth config ID',
              align: 'start',
              sortable: true,
              value: '_id',
            },
            {
              text: 'Auth config type',
              align: 'start',
              value: 'authType',
            },
            {
              text: 'Actions',
              align: 'end',
              sortable: false,
              value: 'actions',
            },
          ],"items":_vm.authConfigList,"items-per-page":5},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-container',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editAuthConfig(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-eye ")])],1)],1)]}}],null,true)})],1)],1)],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }