<template>
    <v-app>

        <TranslateString v-if="showTradModal" :ilang="targetLang" @closed="showTradModal = false;" @newLang="setLang">
        </TranslateString>

        <v-container>
            <v-form :disabled="!editing">

                <v-row>
                    <v-col>
                        <h2>{{ isNew ? 'Add' : 'Edit' }} filter</h2>
                    </v-col>
                    <v-col>
                        <v-card-actions>
                            <v-btn v-if="editing && !isNew" color="red" @click='deleteContainer(currentContainer)'
                                class="white--text">
                                Delete
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color='secondary' @click="$router.push({ name: 'filterManagement' }).catch(() => { });">
                                {{
                                    editing ? 'Cancel' : 'Close'
                                }}
                            </v-btn>
                            <v-btn v-if="editing" color="success" @click='saveFilter()'>
                                {{
                                    isNew ? 'Add' : 'Save'
                                }}
                            </v-btn>

                            <v-btn v-if="!editing" :color="isNew ? 'success' : 'primary'" @click='editing = true'>
                                Edit
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols='6'>
                        <v-text-field label='Filter ID' required v-model='currentFilter._id' :disabled="!isNew">
                        </v-text-field>
                    </v-col>
                    <v-col cols='6'>
                        <v-text-field label='Filter name' required v-model='currentFilter.nome'>
                        </v-text-field>
                    </v-col>

                    <v-col cols='6'>
                        <v-text-field label='Filter description' required v-if="currentFilter.desc"
                            v-model='currentFilter.desc.it_IT' @click="openTrad(currentFilter.desc)">
                        </v-text-field>
                    </v-col>
                    <v-col cols='2'>
                        <v-text-field label='Max results' required type="number" v-model='currentFilter.maxResult'>
                        </v-text-field>
                    </v-col>
                    <v-col cols='2'>
                        <v-autocomplete :items='containersNames' label='Containers' multiple
                            v-model="currentFilter.archivi">
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="2">
                        <v-select :items="config.actionTypes" label='Action' v-model="currentFilter.action" multiple>
                        </v-select>
                    </v-col>

                    <v-alert type="error" v-if="errorShowFilter" width="100%">
                        Error: {{ errorTextFilter }}
                    </v-alert>
                </v-row>

                <h3 class="mt-5">Fields management</h3>
                <v-sheet elevation="2" class="pa-5 mt-10">
                    <v-row class="" style="border: 3px; border-color: black;">
                        <v-col cols="12">
                            <h3>{{ inUpdate ? 'Edit existing' : 'Add new' }} field</h3>
                        </v-col>

                        <v-col cols="4">
                            <v-text-field label='Field name' required v-model='fieldBuilder.name'>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-select :items='config.typeWeb' label='Type' v-model="fieldBuilder.type">
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-select
                                :items='[{ text: "NULL", value: null }, { text: "Simple", value: "simple" }, { text: "Custom", value: "custom" }]'
                                label='Pattern' v-model="fieldBuilder.patternType"
                                :disabled="(inUpdate === true && 'pattern' in fieldBuilder)">
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox label="Mandatory" v-model='fieldBuilder.mandatory'>
                            </v-checkbox>
                        </v-col>

                        <v-col cols="12" v-if="fieldBuilder.patternType !== null">
                            <v-text-field :label="fieldBuilder.patternType === 'simple' ? 'Length' : 'Regex'" required
                                v-model='fieldBuilder.pattern'
                                :type="(fieldBuilder.patternType === 'simple' ? 'number' : 'text')">
                            </v-text-field>
                        </v-col>

                        <v-col cols="6" v-if="fieldBuilder.type === 'date'">
                            <v-text-field label="Format IN" required v-model='fieldBuilder.format_input'>
                            </v-text-field>
                        </v-col>

                        <v-col cols="6" v-if="fieldBuilder.type === 'date'">
                            <v-text-field label="Format OUT" required v-model='fieldBuilder.formatOut'>
                            </v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-autocomplete :items='currentFieldsNames' label='Dependencies'
                                v-model="fieldBuilder.dipendenze" multiple>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                            <v-autocomplete :items='currentFieldsNames' label='Parameters' v-model="fieldBuilder.parametri"
                                multiple>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                            <v-autocomplete :items='currentFieldsNames' label='Load' v-model="fieldBuilder.load" multiple>
                            </v-autocomplete>
                        </v-col>

                        <v-col :cols="currentFilter.archivi.length > 1 ? 4 : 6">
                            <v-text-field label='Query' v-model='fieldBuilder.query'>
                            </v-text-field>
                        </v-col>
                        <v-col :cols="currentFilter.archivi.length > 1 ? 4 : 6">
                            <v-text-field label='Multivalue' v-model='fieldBuilder.multivalue'>
                            </v-text-field>
                        </v-col>
                        <v-col cols='4' v-if="currentFilter.archivi.length > 1">
                            <v-autocomplete :items='containersNames' label='Target containers' multiple
                                v-model="fieldBuilder.archiviSearch">
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="6">
                            <v-select v-if="fieldBuilder.type === 'select'" :items='config.serviceMethods'
                                label='Service method' clearable v-model="fieldBuilder.service.tipo">
                            </v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label='Service URL' v-model='fieldBuilder.service.url'
                                v-if="fieldBuilder.service.tipo != null">
                            </v-text-field>
                        </v-col>

                        <v-col cols="4" v-if="fieldBuilder.service.tipo != null">
                            <v-text-field label='Auth token name' v-model='fieldBuilder.service.auth.token'>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="fieldBuilder.service.tipo != null">
                            <v-text-field label='Auth header key' v-model='fieldBuilder.service.auth.headerName'>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4" v-if="fieldBuilder.service.tipo != null">
                            <v-text-field label='Auth header value' v-model='fieldBuilder.service.auth.headerValue'>
                            </v-text-field>
                        </v-col>

                        <v-col cols="6" v-if="fieldBuilder.service.tipo != null && fieldBuilder.service.tipo != 'GET'">
                            <v-text-field label='Service Body' v-model='fieldBuilder.service.body'>
                            </v-text-field>
                        </v-col>
                        <v-col :cols="fieldBuilder.service.tipo !== 'GET' ? 6 : 12"
                            v-if="fieldBuilder.service.tipo != null">
                            <v-text-field label='Service Options' v-model='fieldBuilder.service.options'>
                            </v-text-field>
                        </v-col>

                        <v-alert type="error" v-if="errorShowField" width="100%">
                            Error: {{ errorTextField }}
                        </v-alert>

                        <v-btn :disabled="!editing" :color="inUpdate ? 'primary' : 'success'" @click='addField()'
                            class="mt-10">
                            {{ inUpdate ? 'Update field' : 'Add field' }}
                        </v-btn>
                    </v-row>
                </v-sheet>

                <v-row class="mt-10">
                    <v-col cols='12'>
                        <v-text-field label='Search field' required v-model='searchQueryField'></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-data-table fixed-header height="352px" :search="searchQueryField" :headers="[
                            {
                                text: 'Field name',
                                align: 'start',
                                value: 'name',
                                sortable: true,
                            },
                            {
                                text: 'Type',
                                align: 'start',
                                value: 'type',
                                sortable: true,
                            },
                            {
                                text: 'Mandatory',
                                align: 'start',
                                value: 'mandatory',
                                sortable: true,
                            },
                            {
                                text: 'Actions',
                                align: 'end',
                                sortable: false,
                                value: 'actions'
                            },
                        ]" :items="currentFields" :items-per-page="5">

                            <template v-slot:[`item.actions`]="{ item }">
                                <!-- Actions for every row -->

                                <v-container class="d-flex flex-row-reverse">
                                    <v-btn @click="editField(item)" class="ml-2" color="primary" :disabled="!editing">
                                        <v-icon color="white">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>

                                    <v-btn @click="deleteField(item)" color="red" v-if="!inUpdate" :disabled="!editing">
                                        <v-icon color="white">
                                            mdi-trash-can
                                        </v-icon>
                                    </v-btn>
                                </v-container>
                            </template>

                        </v-data-table>
                    </v-col>
                </v-row>

                <h3 class="mt-5">Metas management</h3>

                <v-row class="mt-10">
                    <v-col cols='12'>
                        <v-text-field label='Search meta' required v-model='searchQueryMeta'></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-data-table fixed-header height="382px" :search="searchQueryMeta" :headers="[
                            {
                                text: 'Meta name',
                                align: 'start',
                                value: 'name',
                                sortable: true,
                            },
                            {
                                text: 'To return',
                                align: 'start',
                                value: 'toreturn',
                            },
                            {
                                text: 'Show table',
                                align: 'start',
                                value: 'showtable',
                                sortable: true,
                            },
                            {
                                text: 'Order table',
                                align: 'start',
                                value: 'ordertable',
                                sortable: true,
                            },
                            {
                                text: 'Translate',
                                align: 'end',
                                sortable: false,
                                value: 'actions'
                            },
                        ]" :items="currentMetas" :items-per-page="5">

                            <template v-slot:[`item.toreturn`]="{ item }">
                                <v-checkbox v-model="item.toreturn">
                                </v-checkbox>
                            </template>

                            <template v-slot:[`item.showtable`]="{ item }">
                                <v-checkbox v-model="item.showtable">
                                </v-checkbox>
                            </template>

                            <template v-slot:[`item.ordertable`]="{ item }">
                                <v-checkbox v-model="item.ordertable">
                                </v-checkbox>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <!-- Actions for every row -->

                                <v-container class="d-flex flex-row-reverse">
                                    <v-btn @click="openTrad(item.trad)" class="ml-2" color="primary" :disabled="!editing">
                                        <v-icon color="white">
                                            mdi-translate
                                        </v-icon>
                                    </v-btn>
                                </v-container>
                            </template>

                        </v-data-table>
                    </v-col>
                </v-row>

            </v-form>

        </v-container>

</v-app>
</template>

<script>
import TranslateString from '.././TranslateString.vue'

//const config = require('../../config');

export default {
    name: 'FilterDetails',

    components: { TranslateString },

    data: () => ({
        // eslint-disable-next-line no-undef
        config: config,

        isNew: false,
        currentFilter: { pdfname: {}, archivi: [] }, // Current filter data
        currentFields: [], // Current fields array
        currentFieldsNames: [],

        currentMetas: [],
        currentMetasNames: [], // Current metas array

        containersNames: [],

        fieldBuilder: { service: { auth: {} }, patternType: null }, // Form for field builder
        inUpdate: false, // Enable update mode
        inUpdateIndex: -1, // Currently item in update

        searchQueryField: '', // Search string in fields table
        searchQueryMeta: '', // Search string in metas table

        // Error feedbacks
        errorShowFilter: false,
        errorShowField: false,
        errorTextFilter: '',
        errorTextField: '',

        showTradModal: false,
        targetLang: {},

        editing: false, // Enable or disable forms
    }),

    methods: {
        removeUnionChip(chip) {
            this.currentFilter.pdfname[this.currentFilter.nome].splice(this.currentFilter.pdfname[this.currentFilter.nome].indexOf(chip), 1);
        },

        addField() { // Add or update meta
            var newField = {};

            this.errorShowField = false;
            this.errorTextField = '';


            ////////////////////////////////////////////////////////////////////////////////// DA RIFARE
            var countedMandatoryMetas = 2;
            for (const key of Object.keys(this.fieldBuilder)) { // Check for mandatory attributes
                if (['name', 'type'].includes(key) && this.fieldBuilder[key] != '')
                    countedMandatoryMetas--;
            }
            ////////////////////////////////////////////////////////////////////////////////// DA RIFARE


            if (countedMandatoryMetas != 0) { // Check for constraints
                this.errorShowField = true;
                this.errorTextField = 'Fill mandatory fields';

                return;
            }

            if (this.fieldBuilder.patternType === 'simple') // Check pattern type
                this.fieldBuilder.pattern = `^.{0,${this.fieldBuilder.pattern}}$`;

            if (this.fieldBuilder.patternType === null) // Check pattern type
                delete this.fieldBuilder.pattern;

            if (this.fieldBuilder.mandatory === false) // Check pattern type
                delete this.fieldBuilder.mandatory;

            for (const attribute of this.currentFields) { // Iterate fields and find ones with same name
                if ((attribute.name === this.fieldBuilder.name) && this.inUpdateIndex !== this.currentFields.indexOf(attribute)) {
                    this.errorShowField = true;
                    this.errorTextField = 'Check uniqueness of field name';

                    return;
                }
            }

            for (const key in this.fieldBuilder) { // Build newField by given params
                const element = this.fieldBuilder[key];
                newField[key] = element;
            }

            if (this.inUpdate) { // Check if is updating or creating a new field
                var oldFieldName = this.currentFields[this.inUpdateIndex].name;
                var newFieldName = newField.name;

                this.currentFields[this.inUpdateIndex] = newField;

                this.$set(this.currentFieldsNames, this.inUpdateIndex, newField.name); // Setting cell by index doesn't work

                if (oldFieldName !== newFieldName) { // If name is changed
                    for (const field of this.currentFields) {
                        var index = this.currentFields.indexOf(field);

                        this.currentFields[index].parametri = this.currentFields[index].parametri?.map(function (x) {
                            if (oldFieldName === x)
                                return newFieldName;
                            else
                                return x;
                        });

                        this.currentFields[index].load = this.currentFields[index].load?.map(function (x) {
                            if (oldFieldName === x)
                                return newFieldName;
                            else
                                return x;
                        });

                        this.currentFields[index].dipendenze = this.currentFields[index].dipendenze?.map(function (x) {
                            if (oldFieldName === x)
                                return newFieldName;
                            else
                                return x;
                        });
                    }
                }

            } else {
                this.currentFields.push(newField);
                this.currentFieldsNames.push(newField.name);
            }

            this.fieldBuilder = { patternType: null, service: { auth: {} } }; // Clear field builder
            this.inUpdate = false; // Disable update mode
            this.inUpdateIndex = -1;
        },

        saveFilter() { // Insert or update filter data
            this.errorShowFilter = false;
            this.errorTextFilter = '';

            if (!this.currentFilter._id || !this.currentFilter.nome) { // Check for mandatory filter parameters
                this.errorShowFilter = true;
                this.errorTextFilter = 'Fill Filter ID and Name';
                return;
            }


            this.currentFilter.isNew = this.isNew; // Set new propriety on filter (api services needs this)
            this.currentFilter.filtri = this.currentFields; // Set current fields in filter

            this.currentFilter.fieldsToReturn = [];
            this.currentFilter.attributes = [];

            for (const meta of this.currentMetas) {

                var node = {
                    name: meta.name,
                    trad: meta.trad,
                    order: meta.ordertable,
                };

                node.showTable = meta.showtable ? "S" : "N";

                this.currentFilter.attributes.push(node)

                if (meta.toreturn) // Build To Return array
                    this.currentFilter.fieldsToReturn.push(meta.name)
            }

            this.axios
                .post('/mng/insert/filters', this.currentFilter).then(() => {
                    this.$router.push({ name: 'filterManagement' }).catch(() => { });
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })
        },

        editField(targetField) { // Edit existing field
            var newField = { ...targetField }; // Duplicate field for edit

            this.inUpdate = true; // Enable update mode

            if (newField.pattern) // Manipulate pattern type if pattern exists
                newField.patternType = 'custom';
            else
                newField.patternType = null;

            if (newField.service === undefined) // Set default service obj structure
                newField.service = {
                    url: null,
                    tipo: null,
                    body: null,
                    options: null,
                }

            this.inUpdateIndex = this.currentFields.indexOf(targetField); // Set index of item in update mode
            this.fieldBuilder = newField;
        },

        deleteField(targetField) { // Delete existing field
            this.currentFields.splice(this.currentFields.indexOf(targetField), 1);

            var oldFieldName = targetField.name;

            for (const field of this.currentFields) { // Remove all strings in array
                var index = this.currentFields.indexOf(field);

                this.currentFields[index].parametri = this.currentFields[index].parametri?.filter(function (x) {
                    if (oldFieldName === x)
                        return false;
                    else
                        return true;
                });

                this.currentFields[index].load = this.currentFields[index].load?.filter(function (x) {
                    if (oldFieldName === x)
                        return false;
                    else
                        return true;
                });

                this.currentFields[index].dipendenze = this.currentFields[index].dipendenze?.filter(function (x) {
                    if (oldFieldName === x)
                        return false;
                    else
                        return true;
                });
            }

        },

        deleteFilter(filter) { // Delete existing filter
            this.axios
                .post("/mng/delete/filters", { // Send delete request
                    _id: filter._id,
                }).then(() => { // Refresh filter list
                    this.$router.push({ name: 'filterManagement' }).catch(() => { });
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })
        },

        openTrad(targetLang) {
            this.showTradModal = true;
            this.targetLang = targetLang;
        },

        setLang(newLang) {
            this.targetLang = newLang;
        }
    },

    mounted: function () {
        var _id = this.$route.params.id; // Get id from url

        if (_id === 'new') { // Check if a new filter has to be created
            this.isNew = true;
            this.editing = true

            this.currentFilter.pdfname = {}
            this.currentFilter.pdfname[this.currentFilter.nome] = [];

            this.axios
                .post(`/mng/list/containers`, {}).then(response => {

                    this.containersNames = response.data.data.map(c => c._id);

                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })

        } else {
            this.axios
                .post(`/mng/read/filters`, { _id: _id }).then(response => {
                    this.currentFilter = { ...response.data.data }[0];
                    this.currentFields = this.currentFilter.filtri; // Set current fields to object filters

                    for (const field of this.currentFields) { // Build current fields names for arrays dependencies - params - load
                        this.currentFieldsNames.push(field.name);
                    }

                    this.axios
                        .post(`/mng/list/containers`, {}).then(response => {

                            this.containersNames = response.data.data.map(c => c._id);

                            this.axios
                                .post(`/mng/read/metadata`, { _id: _id }).then(response => {

                                    this.currentMetasNames = response.data.data;

                                    for (const iterator of this.currentMetasNames) {
                                        var node = {};
                                        var attrIndex = this.currentFilter.attributes.indexOf(this.currentFilter.attributes.find(a => a.name === iterator));

                                        node.name = iterator;
                                        node.toreturn = this.currentFilter.fieldsToReturn.includes(iterator);

                                        if (attrIndex !== -1) {
                                            node.showtable = this.currentFilter.attributes[attrIndex].showTable === 'S';
                                            node.ordertable = this.currentFilter.attributes[attrIndex].order;

                                            node.trad = this.currentFilter.attributes[attrIndex].trad;
                                        } else {
                                            node.showtable = false;
                                            node.ordertable = false;

                                            node.trad = {
                                                it_IT: '',
                                                en_GB: '',
                                                de_DE: '',
                                                fr_FR: '',
                                                es_ES: '',
                                            }
                                        }

                                        this.currentMetas.push(node);
                                    }
                                })
                        })
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })
        }
    }
};

</script>