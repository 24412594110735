import Vue from 'vue'
import Router from 'vue-router'

import HomePage from './components/HomePage.vue'

import AuthConfigDetails from './components/AuthConfiguration/AuthConfigDetails.vue'
import AuthConfigList from './components/AuthConfiguration/AuthConfigList.vue'

import UserAuthorization from './components/UserAuthorization/UserAuthorization.vue'
import UserManagement from './components/UserManagement/UserManagement.vue'

import ContainerList from './components/ContainerManagement/ContainerList.vue'
import ContainerDetails from './components/ContainerManagement/ContainerDetails.vue'

import ImportConfigurationDetails from './components/ImportConfiguration/ImportConfigurationDetails.vue'
import ImportConfigurationList from './components/ImportConfiguration/ImportConfigurationList.vue'

import FilterList from './components/FilterManagement/FilterList.vue'
import FilterDetails from './components/FilterManagement/FilterDetails.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'homePage',
            component: HomePage,
        },
        {
            path: '/userManagement',
            name: 'userManagement',
            component: UserManagement,
        },
        {
            path: '/userAuthorization',
            name: 'userAuthorization',
            component: UserAuthorization,
        },
        {
            path: '/authConfiguration',
            name: 'authConfiguration',
            component: AuthConfigList,
        },
        {
            path: '/authConfiguration/:id',
            name: 'authConfiguration_id',
            component: AuthConfigDetails
        },
        {
            path: '/containerManagement',
            name: 'containerManagement',
            component: ContainerList
        },
        {
            path: '/containerManagement/:id',
            name: 'containerManagement_id',
            component: ContainerDetails
        },
        {
            path: '/importManagement',
            name: 'importManagement',
            component: ImportConfigurationList
        },
        {
            path: '/importManagement/:id',
            name: 'importManagement_id',
            component: ImportConfigurationDetails
        },
        {
            path: '/filterManagement',
            name: 'filterManagement',
            component: FilterList
        },
        {
            path: '/filterManagement/:id',
            name: 'filterManagement_id',
            component: FilterDetails
        },
    ]
})
