<template>
    <v-app>
        <v-container>
            <v-form :disabled="!editing">
                <v-row>
                    <v-col>
                        <h2>{{ isNew ? 'Add' : 'Edit' }} container</h2>
                    </v-col>
                    <v-col>
                        <v-card-actions>
                            <v-btn v-if="editing && !isNew" color="red" @click='deleteContainer(currentContainer)'
                                class="white--text">
                                Delete
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color='secondary'
                                @click="$router.push({ name: 'containerManagement' }).catch(() => { });">
                                {{
                                    editing ? 'Cancel' : 'Close'
                                }}
                            </v-btn>
                            <v-btn v-if="editing" color="success" @click='saveContainer()'>
                                {{
                                    isNew ? 'Add' : 'Save'
                                }}
                            </v-btn>

                            <v-btn v-if="!editing" :color="isNew ? 'success' : 'primary'" @click='editing = true'>
                                Edit
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols='6'>
                        <v-text-field label='Container ID' required v-model='currentContainer._id' :disabled="!isNew">
                        </v-text-field>
                    </v-col>

                    <v-col cols='6'>
                        <v-text-field label='Container name' required v-model='currentContainer.nameacm'>
                        </v-text-field>
                    </v-col>

                    <v-col cols='10'>
                        <v-text-field label='Container description' required v-model='currentContainer.descacm'>
                        </v-text-field>
                    </v-col>

                    <v-col cols='2'>
                        <v-select :items='[{ text: "Disable", value: false }, { text: "Enable", value: true }]'
                            label='Multiversion' v-model="currentContainer.multiversion" :disabled="!isNew"></v-select>
                    </v-col>

                    <v-alert type="error" v-if="errorShowContainer" width="100%">
                        Error: {{ errorTextContainer }}
                    </v-alert>
                </v-row>

                <v-sheet elevation="2" class="pa-5 mt-10">
                    <v-row class="" style="border: 3px; border-color: black;">
                        <v-col cols="12">
                            <h3>{{ inUpdate ? 'Edit existing' : 'Add new' }} metadata</h3>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field label='Meta name' required v-model='metaBuilder.metaname'>
                            </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field label='Key CM' required v-model='metaBuilder.keycm'>
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label='Description' required v-model='metaBuilder.desc'>
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field label='Default' required v-model='metaBuilder.default'
                                :type="metaBuilder.typeWeb === 'number' ? 'number' : 'text'">
                            </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox label="Mandatory" v-model='metaBuilder.mandatory'>
                            </v-checkbox>
                        </v-col>

                        <v-col cols="5">
                            <v-select :items='config.typeCm' label='Type CM' v-model="metaBuilder.typeCm">
                            </v-select>
                        </v-col>
                        <v-col cols="5">
                            <v-select :items='config.typeWeb' label='Type Web' v-model="metaBuilder.typeWeb">
                            </v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-select
                                :items='[{ text: "NULL", value: null }, { text: "Simple", value: "simple" }, { text: "Custom", value: "custom" }]'
                                label='Pattern' v-model="metaBuilder.patternType"
                                :disabled="(inUpdate === true && 'pattern' in metaBuilder)"></v-select>
                        </v-col>

                        <v-col cols="12" v-if="metaBuilder.patternType !== null">
                            <v-text-field :label="metaBuilder.patternType === 'simple' ? 'Length' : 'Regex'" required
                                v-model='metaBuilder.pattern'
                                :type="(metaBuilder.patternType === 'simple' ? 'number' : 'text')">
                            </v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <!-- <v-text-field required v-model='metaBuilder.union'>
                                            </v-text-field>-->

                            <v-combobox label='Union' required v-model="metaBuilder.union" clearable multiple
                                hide-details="auto" append-icon="">

                                <template v-slot:selection="{ attrs, item, select, selected }">
                                    <v-chip small v-bind="attrs" :input-value="selected" close @click="select"
                                        @click:close="removeUnionChip(item)">
                                        {{ item }}
                                    </v-chip>
                                </template>
                            </v-combobox>
                        </v-col>

                        <v-alert type="error" v-if="errorShowMetadata" width="100%">
                            Error: {{ errorTextMetadata }}
                        </v-alert>

                        <v-btn :disabled="!editing" :color="inUpdate ? 'primary' : 'success'" @click='addMeta()'
                            class="mt-10">
                            {{ inUpdate ? 'Update meta' : 'Add meta' }}
                        </v-btn>
                    </v-row>
                </v-sheet>

                <v-row class="mt-10">
                    <v-col cols='12'>
                        <v-text-field label='Search attribute' required v-model='searchQuery'></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-data-table fixed-header height="352px" :search="searchQuery" :headers="[
                            {
                                text: 'Meta name',
                                align: 'start',
                                value: 'metaname',
                                sortable: true,
                            },
                            {
                                text: 'Key CM',
                                align: 'start',
                                value: 'keycm',
                                sortable: true,
                            },
                            {
                                text: 'Description',
                                align: 'start',
                                value: 'desc',
                                sortable: false,
                            },
                            {
                                text: 'Pattern',
                                align: 'start',
                                value: 'pattern',
                                sortable: false,
                            },
                            {
                                text: 'Default',
                                align: 'start',
                                value: 'default',
                                sortable: false,
                            },
                            {
                                text: 'Mandatory',
                                align: 'start',
                                value: 'mandatory',
                                sortable: true,
                            },
                            {
                                text: 'Type CM',
                                align: 'start',
                                value: 'typeCm',
                                sortable: false,
                            },
                            {
                                text: 'Type Web',
                                align: 'start',
                                value: 'typeWeb',
                                sortable: false,
                            },
                            {
                                text: 'Actions',
                                align: 'end',
                                sortable: false,
                                value: 'actions'
                            },
                        ]" :items="currentMetas" :items-per-page="5">

                            <template v-slot:[`item.actions`]="{ item }">
                                <!-- Actions for every row -->

                                <v-container class="d-flex flex-row-reverse"
                                    v-if="(!config.defaultMetas.includes(item.metaname))">
                                    <v-btn @click="editMeta(item)" class="ml-2" color="primary" :disabled="!editing">
                                        <v-icon color="white">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>

                                    <v-btn @click="deleteMeta(item)" color="red" :disabled="!editing">
                                        <v-icon color="white">
                                            mdi-trash-can
                                        </v-icon>
                                    </v-btn>
                                </v-container>

                                <v-container class="d-flex flex-row-reverse"
                                    v-if="(config.defaultMetas.includes(item.metaname))">
                                    <v-btn class="ml-2" color="info" disabled>
                                        Read only
                                    </v-btn>
                                </v-container>
                            </template>

                        </v-data-table>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>

</v-app>
</template>

<script>
//const config = require('../../config');
export default {
    name: 'ContainerDetails',

    data: () => ({
        // eslint-disable-next-line no-undef
        config: config,
        isNew: false,
        currentContainer: {}, // Current container data
        currentMetas: [], // Current attributes array

        metaBuilder: { patternType: null }, // Form for attribute builder
        inUpdate: false, // Enable update mode
        inUpdateIndex: -1, // Currently item in update

        searchQuery: '', // Search string in table

        // Error feedbacks
        errorShowContainer: false,
        errorShowMetadata: false,
        errorTextContainer: '',
        errorTextMetadata: '',

        editing: false, // Enable or disable forms
    }),

    methods: {
        removeUnionChip(chip) {
            this.metaBuilder.union.splice(this.metaBuilder.union.indexOf(chip), 1);
        },

        addMeta() { // Add or update meta
            var newMeta = {};

            this.errorShowMetadata = false;
            this.errorTextMetadata = '';


            ////////////////////////////////////////////////////////////////////////////////// DA RIFARE
            var countedMandatoryMetas = 4;
            for (const key of Object.keys(this.metaBuilder)) { // Check for mandatory attributes
                if (['typeCm', 'typeWeb', 'keycm', 'metaname'].includes(key) && this.metaBuilder[key] != '')
                    countedMandatoryMetas--;
            }
            ////////////////////////////////////////////////////////////////////////////////// DA RIFARE


            if (countedMandatoryMetas != 0) { // Check for constraints
                this.errorShowMetadata = true;
                this.errorTextMetadata = 'Fill mandatory fields';

                return;
            }

            if (this.metaBuilder.patternType === 'simple') // Check pattern type
                this.metaBuilder.pattern = `^.{0,${this.metaBuilder.pattern}}$`;

            if (this.metaBuilder.patternType === null) // Check pattern type
                delete this.metaBuilder.pattern;

            if (this.metaBuilder.mandatory === false) // Check pattern type
                delete this.metaBuilder.mandatory;

            for (const attribute of this.currentMetas) { // Iterate attributes and find ones with same metaname and keycm
                if ((attribute.keycm === this.metaBuilder.keycm || attribute.metaname === this.metaBuilder.metaname) && this.inUpdateIndex !== this.currentMetas.indexOf(attribute)) {
                    this.errorShowMetadata = true;
                    this.errorTextMetadata = 'Check uniqueness of Meta name and Key CM';

                    return;
                }
            }

            for (const key in this.metaBuilder) { // Build newMeta by given metas
                const element = this.metaBuilder[key];
                newMeta[key] = element;
            }

            if (this.inUpdate) { // Check if is updating or creating a new meta
                this.currentMetas[this.inUpdateIndex] = newMeta;
            } else {
                this.currentMetas.push(newMeta);
            }

            this.metaBuilder = { patternType: null }; // Clear attribute builder
            this.inUpdate = false; // Disable update mode
            this.inUpdateIndex = -1;
        },

        saveContainer() { // Insert or update container data
            this.errorShowContainer = false;
            this.errorTextContainer = '';

            if (!this.currentContainer._id || !this.currentContainer.nameacm || this.currentContainer.multiversion === undefined) { // Check for mandatory container parameters
                this.errorShowContainer = true;
                this.errorTextContainer = 'Fill Container ID, Name and Multiversion fields';
                return;
            }

            this.currentContainer.keyattr = {}; // Delete current metas in container

            for (const meta of this.currentMetas) { // Iterate in current metas
                this.currentContainer.keyattr[meta.metaname] = meta;
                delete this.currentContainer.keyattr[meta.metaname].metaname; // Delete metaname field from each meta
            }

            this.currentContainer.isNew = this.isNew; // Set new propriety on container (api services needs this)

            this.axios
                .post('/mng/insert/containers', this.currentContainer).then(() => {
                    this.$router.push({ name: 'containerManagement' }).catch(() => { });
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })
        },

        editMeta(targetMeta) { // Edit existing meta
            var newMeta = { ...targetMeta }; // Duplicate meta for edit

            this.inUpdate = true; // Enable update mode

            if (newMeta.pattern) // Manipulate pattern type if pattern exists
                newMeta.patternType = 'custom';
            else
                newMeta.patternType = null;

            this.inUpdateIndex = this.currentMetas.indexOf(targetMeta); // Set index of item in update mode
            this.metaBuilder = newMeta;
        },

        deleteMeta(targetMeta) { // Delete existing meta
            this.currentMetas.splice(this.currentMetas.indexOf(targetMeta), 1);
        },

        deleteContainer(container) {
            // Delete existing container
            this.axios
                .post("/mng/delete/containers", {
                    // Send delete request
                    _id: container._id,
                })
                .then(() => {
                    this.$router.push({ name: 'containerManagement' }).catch(() => { });
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })
        },
    },

    mounted: function () {
        var _id = this.$route.params.id; // Get id from url

        if (_id === 'new') { // Check if a new container has to be created
            this.isNew = true;
            this.editing = true

            for (const defaultMeta of this.config.defaultMetas) {
                this.currentMetas.push({
                    metaname: defaultMeta,
                    keycm: defaultMeta,
                    typeWeb: 'string',
                    typeCm: 'STRING',
                })
            }
        } else {
            this.axios
                .post(`/mng/read/containers`, { _id: _id }).then(response => {

                    this.currentContainer = { ...response.data.data }[0];

                    for (const dbMetaKey in this.currentContainer.keyattr) { // Add metaname propriety to each meta
                        var originMeta = this.currentContainer.keyattr[dbMetaKey];
                        var newMeta = { metaname: dbMetaKey, ...originMeta };

                        this.currentMetas.push(newMeta);
                    }
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                });
        }
    }
};

</script>