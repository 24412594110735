var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',[_c('h2',[_vm._v("Import list")]),_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Search container","required":""},on:{"input":function($event){return _vm.getImportList()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":[
          {
            text: 'Container ID',
            align: 'start',
            sortable: true,
            value: '_id',
          },
          {
            text: 'Container name',
            align: 'start',
            value: 'nameacm',
          },
          {
            text: 'Container description',
            align: 'start',
            value: 'descacm',
          },
          {
            text: 'Actions',
            align: 'end',
            sortable: false,
            value: 'actions',
          },
        ],"items":_vm.containersList,"items-per-page":5},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-container',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editImport(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-eye ")])],1)],1)]}}],null,true)})],1)],1)],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }