<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ isNew ? "Add" : "Edit" }} user</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field label="UID" required v-model="userDetails.UID" :disabled="!isNew"></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field label="User type" required v-model="userDetails.userType" :disabled="!isNew"></v-text-field>
            </v-col>

            <v-alert type="error" v-if="errorShow" width="100%">
              Error: {{ errorText }}
            </v-alert>

            <v-col cols="12">
              <v-text-field label="Search container" v-model="searchQuery"
                @keyup.enter.native="searchContainer(searchQuery)"></v-text-field>
            </v-col>

            <v-data-table fixed-header height="382px" :headers="[
              {
                text: 'Container name',
                align: 'start',
                value: 'ContainerName',
                sortable: true,
              },
              {
                text: 'Read',
                align: 'start',
                value: 'READ',
                sortable: false,
              },
              {
                text: 'Write',
                align: 'start',
                value: 'WRITE',
                sortable: false,
              },
              {
                text: 'Edit',
                align: 'start',
                value: 'EDIT',
                sortable: false,
              },
              {
                text: 'Delete',
                align: 'start',
                value: 'DELETE',
                sortable: false,
              },
              {
                text: 'Delete Logic',
                align: 'start',
                value: 'DELETE_LOGIC',
                sortable: false,
              },
            ]" :items="pexRows" :items-per-page="5">
              <template v-slot:[`item.${pexType}`]="{ item }" v-for="(pexType, i) in this.pexKeys">
                <v-checkbox v-model="item[`${pexType}`]" :key="i" @change="flagPex(item, pexType)">
                </v-checkbox>
              </template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="closeDetail()">Cancel</v-btn>
        <v-btn :color="isNew ? 'success' : 'primary'" @click="saveDetail()">{{
          isNew ? "Add" : "Save"
        }}</v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
          
<script>
//const config = require('../../config');
// eslint-disable-next-line no-undef
const pexKeys = config.pexTypes;

export default {
  name: "UserAuthorizationDetails",

  props: {
    inputUser: { type: Object, default: () => { } },
    inputIsNew: { type: Boolean, default: false },
    inputUserList: { type: Object, default: () => { } },
  },

  data: function () {
    return {
      dialog: true,
      userDetails: {},
      containersNamesList: [],
      pexRows: [],
      pexKeys: pexKeys,
      searchQuery: "",
      userTypes: [],
      isNew: false,
      errorShow: false,
      errorText: ""
    };
  },
  methods: {
    flagPex(item, selectedPex) {
      const currentVal = item[selectedPex];
      for (const pexKey in item) {
        if (this.pexKeys.includes(pexKey)) {
          // If is a pex

          if (currentVal) {
            // Go left or right and activate or deactivate
            if (
              this.pexKeys.indexOf(pexKey) < this.pexKeys.indexOf(selectedPex)
            )
              item[pexKey] = true;
          } else if (
            this.pexKeys.indexOf(pexKey) > this.pexKeys.indexOf(selectedPex)
          )
            item[pexKey] = false;
        }
      }
    },
    closeDetail() {
      this.$emit("closed", true);
    },
    saveDetail() {
      if (!this.userDetails.UID || !this.userDetails.userType) {
        this.errorShow = true;
        this.errorText = "Fill UID and User type fields";
        return;
      } else this.errorShow = false;

      var newUser = {
        UID: this.userDetails.UID,
        userType: this.userDetails.userType,
        authorizations: {},
      };
      if (!this.isNew)
        // If user is new has no _id
        newUser._id = this.userDetails._id;

      for (const pexRow of this.pexRows) {
        // Iterate in pex rows (used in table)
        var pexNode = []; // Pex list in single
        for (const pexKey of pexKeys) {
          // Iterate in permissions
          if (pexRow[pexKey] === true)
            // If user has flag
            pexNode.push(pexKey); // Push permission
        }
        if (pexNode.length > 0)
          // Check if user has at least one basic pex for container
          newUser.authorizations[pexRow.ContainerName] = pexNode; // Set pex array by container name as key
      }
      this.axios
        .post("/mng/insert/users", newUser)
        .then(() => {
          this.$emit("closed", true);
        }).catch((err) => {
          console.error(err)
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error ' + err.response.data.msg[0].msgId,
            text: err.response.data.msg[0].msgTxt
          })
        })
    },
    searchContainer(query) {
      this.containersNamesList = [];
      this.pexRows = [];
      let body = {};
      if (query) {
        body.searchParam = query;
      }
      this.axios.post("/mng/list/containers", body).then((response) => {
        // Fetch container list
        this.containersNamesList = response.data.data;
        for (let containerName of this.containersNamesList) {
          // Iterate in containers list by name
          containerName = containerName._id;
          var pexNode = { ContainerName: containerName }; // Generate permission node with container name
          for (const pexKey of pexKeys) {
            // Iterate in permission
            if (!this.isNew && this.userDetails.authorizations[containerName])
              // If user is not new and has container access
              pexNode[pexKey] =
                this.userDetails.authorizations[containerName].includes(pexKey);
            // Check if has basic permission
            else pexNode[pexKey] = false; // Denied by default
          }
          this.pexRows.push(pexNode); // Push permission node
        }
      }).catch((err) => {
        console.error(err)
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error ' + err.response.data.msg[0].msgId,
          text: err.response.data.msg[0].msgTxt
        })
      });
    },
  },
  created: function () {
    this.searchQuery = "";
    // eslint-disable-next-line no-undef
    this.userTypes = config.userTypes;
    this.userDetails = this.inputUser;
    this.isNew = this.inputIsNew;
    this.searchContainer();
  },
};
</script>
