<template>
    <v-app>
        <v-container>
            <v-form :disabled="!editing">
                <v-row>
                    <v-col>
                        <h2>{{ isNew ? 'Add' : 'Edit' }} auth configuration</h2>
                    </v-col>
                    <v-col>
                        <v-card-actions>
                            <v-btn v-if="editing && !isNew" color="red" @click='deleteAuthConfig(currentAuthConfig)'
                                class="white--text">
                                Delete
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color='secondary' @click="$router.push({ name: 'authConfiguration' }).catch(() => { });">
                                {{
                                    editing ? 'Cancel' : 'Close'
                                }}
                            </v-btn>
                            <v-btn v-if="editing" color="success" @click='saveAuthConfig()'>
                                {{
                                    isNew ? 'Add' : 'Save'
                                }}
                            </v-btn>

                            <v-btn v-if="!editing" :color="isNew ? 'success' : 'primary'" @click='editing = true'>
                                Edit
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols='4'>
                        <v-text-field label='Auth config ID' required v-model='currentAuthConfig._id' disabled>
                        </v-text-field>
                    </v-col>

                    <v-col cols='4'>
                        <v-text-field label='Auth config name' required v-model='currentAuthConfig.authType'
                            :disabled="!isNew">
                        </v-text-field>
                    </v-col>

                    <v-col cols='4'>
                        <v-select :items='config.authConfigTypes' label='Auth config type'
                            v-model="currentAuthConfig.type"></v-select>
                    </v-col>

                    <v-col cols='12'>
                        <v-combobox label='Allowed portals' v-model="currentAuthConfig.allowedPortals" multiple clearable
                            chips></v-combobox>
                    </v-col>

                    <v-col cols='4'>
                        <v-text-field label='Token name' required v-model='currentAuthConfig.tokenName' :disabled="!isNew">
                        </v-text-field>
                    </v-col>

                    <v-col cols='4'>
                        <v-select :items='config.authConfigTokenTypes' label='Token type'
                            v-model="currentAuthConfig.tokenType" @change="resetParams()"></v-select>
                    </v-col>

                    <v-col cols='4'>
                        <v-text-field label='Token field name' required v-model='currentAuthConfig.tokenFieldName'>
                        </v-text-field>
                    </v-col>

                    <v-col cols='12'>
                        <v-row v-if="currentAuthConfig.type === 'SERVICE'">
                            <v-col cols="4">
                                <v-text-field label='Service URL' required v-model='currentAuthConfig.params.url'>
                                </v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-select :items='config.serviceMethods' label='Service Method'
                                    v-model="currentAuthConfig.params.method"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label='Service Body (JSON)' required v-model='currentAuthConfig.params.body'>
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row v-if="currentAuthConfig.type === 'LDAP'">
                            <v-col cols="4">
                                <v-text-field label='LDAP URL' required v-model='currentAuthConfig.params.url'>
                                </v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label='LDAP Search Base' required
                                    v-model='currentAuthConfig.params.searchBase'>
                                </v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label='LDAP Attribute' required v-model='currentAuthConfig.params.attribute'>
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row v-if="currentAuthConfig.type === 'MOCK'">
                            <v-col cols="12">
                                <v-text-field label='Mock token' required v-model='currentAuthConfig.params.token'>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-alert type="error" v-if="errorShowAuthConfig" width="100%">
                        Error: {{ errorTextAuthConfig }}
                    </v-alert>
                </v-row>

            </v-form>
        </v-container>

</v-app>
</template>

<script>
//const config = require('../../config');
export default {
    name: 'AuthConfigDetails',

    data: () => ({
        // eslint-disable-next-line no-undef
        config: config,
        isNew: false,
        currentAuthConfig: { params: {} }, // Current auth config data

        // Error feedbacks
        errorShowAuthConfig: false,
        errorTextAuthConfig: '',

        editing: false, // Enable or disable forms
    }),

    methods: {
        resetParams() {
            this.$set(this.currentAuthConfig, 'params', {})
        },
        saveAuthConfig() { // Insert or update auth config data
            this.errorShowAuthConfig = false;
            this.errorTextAuthConfig = '';

            if (!this.currentAuthConfig.authType || !this.currentAuthConfig.type) { // Check for mandatory auth config parameters
                this.errorShowAuthConfig = true;
                this.errorTextAuthConfig = 'Fill Name and Type fields';
                return;
            }

            let payload = { ...this.currentAuthConfig }

            if (this.isNew)
                delete payload._id

            if (payload.type === 'SERVICE' && payload.params && payload.params.body) {
                let params = { ...payload.params }
                try {
                    params.body = JSON.parse(params.body)
                    payload.params = params
                } catch (e) {
                    this.errorShowAuthConfig = true;
                    this.errorTextAuthConfig = 'Unable to parse SERVICE body';
                    return;
                }
            }

            this.axios
                .post('/mng/insert/auth', payload).then(() => {
                    this.$router.push({ name: 'authConfiguration' }).catch(() => { });
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })
        },

        deleteAuthConfig(authConfig) {
            // Delete existing auth config
            this.axios
                .post("/mng/delete/auth", {
                    // Send delete request
                    _id: authConfig._id,
                })
                .then(() => {
                    this.$router.push({ name: 'authConfiguration' }).catch(() => { });
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })
        },
    },

    mounted: function () {
        let _ = this

        var _id = this.$route.params.id; // Get id from url

        if (_id === 'new') { // Check if a new container has to be created
            this.isNew = true;
            this.editing = true
        } else {
            this.axios
                .post(`/mng/read/auth`, { _id: _id }).then(response => {

                    this.currentAuthConfig = { ...response.data.data }[0];

                    if (_.currentAuthConfig.type === 'SERVICE' && _.currentAuthConfig.params && _.currentAuthConfig.params.body)
                        _.currentAuthConfig.params.body = JSON.stringify(_.currentAuthConfig.params.body)
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })
        }
    }
};

</script>