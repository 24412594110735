<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ isNew ? "Add" : "Edit" }} user</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field label="UID" required v-model="userDetails.UID" :disabled="!isNew"></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field label="User type" required v-model="userDetails.userType" :disabled="!isNew"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Password" required v-model="userDetails.password" type="password"
                @focus="passwordHint(false)" @blur="passwordHint(true)"></v-text-field>
            </v-col>

            <v-alert type="error" v-if="errorShow" width="100%">
              Error: {{ errorText }}
            </v-alert>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="closeDetail()">Cancel</v-btn>
        <v-btn :color="isNew ? 'success' : 'primary'" @click="saveDetail()">{{
          isNew ? "Add" : "Save"
        }}</v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
          
<script>
//const config = require('../../config');

export default {
  name: "UserAuthorizationDetails",

  props: {
    inputUser: { type: Object, default: () => { } },
    inputIsNew: { type: Boolean, default: false },
    inputUserList: { type: Object, default: () => { } },
  },

  data: function () {
    return {
      dialog: true,
      userDetails: {},
      userTypes: [],
      isNew: false,
      errorShow: false,
      errorText: "",

      passwordPlaceholder: 'UTENTE_HA_UNA_PASSWORD'
    };
  },
  methods: {
    passwordHint(show) {

      if (show && this.userDetails.password === '') {
        this.$set(this.userDetails, 'password', this.passwordPlaceholder.toString())
      }

      if (!show && this.userDetails.password === this.passwordPlaceholder) {
        this.$set(this.userDetails, 'password', '')
      }
    },
    closeDetail() {
      this.$emit("closed", true);
    },
    saveDetail() {
      if (!this.userDetails.UID || !this.userDetails.userType) {
        this.errorShow = true;
        this.errorText = "Fill UID and User type fields";
        return;
      } else this.errorShow = false;

      if (!this.userDetails.password) {
        this.errorShow = true;
        this.errorText = "Fill Password field";
        return;
      } else this.errorShow = false;

      var newUser = { ...this.userDetails }

      if (!this.isNew)
        // If user is new has no _id
        newUser._id = this.userDetails._id;

      // If no new password is setted, delete propriety from update payload
      if (this.userDetails.password === this.passwordPlaceholder)
        delete newUser.password

      this.axios
        .post("/mng/insert/login", newUser)
        .then(() => {
          this.$emit("closed", true);
        }).catch((err) => {
          console.error(err)
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error ' + err.response.data.msg[0].msgId,
            text: err.response.data.msg[0].msgTxt
          })
        })
    },
  },
  created: function () {
    let _ = this

    let payload = {
      // eslint-disable-next-line no-undef
      portal: config.portal,
    }

    _.axios
      .post("/listAuth", payload, { headers: { Authorization: '' } })
      .then((result) => {
        _.userTypes = result.data.data
      }).catch((err) => {
        console.error(err)
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error ' + err.response.data.msg[0].msgId,
          text: err.response.data.msg[0].msgTxt
        })
      })

    this.userDetails = this.inputUser;
    this.isNew = this.inputIsNew;

    if (this.userDetails.withPassword)
      this.$set(this.userDetails, 'password', this.passwordPlaceholder.toString())
  },
};
</script>
