<template>
  <v-app>
    <v-container>
      <h2>Auth configurations list</h2>

      <v-row class="justify-space-between">
        <v-col cols="11">
          <v-text-field label="Search container" required v-model="searchText" @input="getAuthConfigList()">
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn @click="addAuthConfig()" color="success" class="mt-2 mb-2" style="float: right">
            <v-icon> mdi-plus-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table class="elevation-3" :headers="[
            {
              text: 'Auth config ID',
              align: 'start',
              sortable: true,
              value: '_id',
            },
            {
              text: 'Auth config type',
              align: 'start',
              value: 'authType',
            },
            {
              text: 'Actions',
              align: 'end',
              sortable: false,
              value: 'actions',
            },
          ]" :items="authConfigList" :items-per-page="5">
            <template v-slot:[`item.actions`]="{ item }">
              <!-- Actions for every row -->

              <v-container class="d-flex flex-row-reverse">
                <v-btn @click="editAuthConfig(item)" class="ml-2" color="primary">
                  <v-icon color="white"> mdi-eye </v-icon>
                </v-btn>
              </v-container>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <router-view />
</v-app>
</template>

<script>
export default {
  name: "AuthConfigList",

  data: () => ({
    authConfigList: [], // Current container list

    searchText: "", // Text to search (service side)
  }),

  mounted() {
    // Get all containers
    this.axios
      .post(`/mng/list/auth`, { searchParam: this.searchText })
      .then((response) => {
        this.authConfigList = response.data.data;
      }).catch((err) => {
        console.error(err)
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error ' + err.response.data.msg[0].msgId,
          text: err.response.data.msg[0].msgTxt
        })
      });
  },

  methods: {
    editAuthConfig(authConfig) {
      // Open page for container edit
      this.$router.push({ name: `authConfiguration_id`, params: { id: authConfig._id } });
    },

    addAuthConfig() {
      // Open page for container creation
      this.$router.push({ name: `authConfiguration_id`, params: { id: 'new' } });
    },

    getAuthConfigList() {
      // Refresh container list
      this.axios
        .post(`/mng/list/auth`, { searchParam: this.searchText })
        .then((response) => {
          this.authConfigList = response.data.data;
        }).catch((err) => {
          console.error(err)
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error ' + err.response.data.msg[0].msgId,
            text: err.response.data.msg[0].msgTxt
          })
        });
    },
  },
};
</script>