<template>
  <v-app>
    <v-container>
      <h2>Container list</h2>

      <v-row class="justify-space-between">
        <v-col cols="11">
          <v-text-field label="Search container" required v-model="searchText" @input="getContainersList()">
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn @click="addContainer()" color="success" class="mt-2 mb-2" style="float: right">
            <v-icon> mdi-plus-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table class="elevation-3" :headers="[
            {
              text: 'Container ID',
              align: 'start',
              sortable: true,
              value: '_id',
            },
            {
              text: 'Container name',
              align: 'start',
              value: 'nameacm',
            },
            {
              text: 'Container description',
              align: 'start',
              value: 'descacm',
            },
            {
              text: 'Actions',
              align: 'end',
              sortable: false,
              value: 'actions',
            },
          ]" :items="containersList" :items-per-page="5">
            <template v-slot:[`item.actions`]="{ item }">
              <!-- Actions for every row -->

              <v-container class="d-flex flex-row-reverse">
                <v-btn @click="editContainer(item)" class="ml-2" color="primary">
                  <v-icon color="white"> mdi-eye </v-icon>
                </v-btn>
                <v-btn @click="customImport(item)" class="ml-2" color="green">
                  <v-icon color="white"> mdi-import </v-icon>
                </v-btn>

              </v-container>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="importDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Custom import {{ importContainer._id }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field label="Name" v-model="importConfig.name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select :items="['dev', 'production']" label="Environment"
                  v-model="importConfig.environment"></v-select>
              </v-col>
            </v-row>

            <v-alert v-if="[2, 3].includes(importStatus)" :type="importStatus == 2 ? 'success' : 'error'">{{
              importStatus == 2 ? 'Successfully submitted' : 'Error while submitting' }}</v-alert>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="importDialog = false">Cancel</v-btn>
          <v-btn :disabled="[1, 2].includes(importStatus)" :loading="importStatus == 1" color="success"
            @click="startCustomImport()">Start</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <router-view />
</v-app>
</template>

<script>
export default {
  name: "ContainerList",

  data: () => ({
    containersList: [], // Current container list

    searchText: "", // Text to search (service side)

    importDialog: false,
    importContainer: {},
    importConfig: {},
    importStatus: 0, // 0 not sended, 1 loading, 2 ok, 3 error
  }),

  mounted() {
    // Get all containers
    this.axios
      .post(`/mng/list/containers`, { searchParam: this.searchText })
      .then((response) => {
        this.containersList = response.data.data;
      });
  },

  methods: {
    editContainer(container) {
      // Open page for container edit
      this.$router.push({ name: `containerManagement_id`, params: { id: container._id } });
    },

    customImport(container) {
      // Open modal for custom import
      this.importContainer = container

      this.importConfig = {
        container_name: container._id,
        job_type: "import",
      }

      this.importStatus = 0
      this.importDialog = true
    },

    startCustomImport() {
      let c = this.importConfig

      if (!c.name || !c.environment)
        return this.importStatus = 3

      this.importStatus = 1

      this.axios
        .post(`/mng/customImport`, c)
        .then(() => {
          this.importStatus = 2
        })
        .catch((err) => {
          this.importStatus = 3

          console.error(err)
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error ' + err.response.data.msg[0].msgId,
            text: err.response.data.msg[0].msgTxt
          })
        });
    },

    addContainer() {
      // Open page for container creation
      this.$router.push({ name: `containerManagement_id`, params: { id: 'new' } });
    },

    getContainersList() {
      // Refresh container list
      this.axios
        .post(`/mng/list/containers`, { searchParam: this.searchText })
        .then((response) => {
          this.containersList = response.data.data;
        }).catch((err) => {
          console.error(err)
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error ' + err.response.data.msg[0].msgId,
            text: err.response.data.msg[0].msgTxt
          })
        });
    },
  },
};
</script>