var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',[(_vm.showDetails)?_c('UserManagementDetails',{attrs:{"inputUser":_vm.selectedUser,"inputIsNew":_vm.showDetailsIsNew},on:{"closed":function($event){_vm.showDetails = false; _vm.getUsersList();}}}):_vm._e(),_c('h2',[_vm._v("User management")]),_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"label":"Search user","required":""},on:{"input":function($event){return _vm.getUsersList()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-2 mb-2",staticStyle:{"float":"right"},attrs:{"color":"success"},on:{"click":function($event){return _vm.addUser()}}},[_c('v-icon',[_vm._v(" mdi-plus-box ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"search":_vm.searchText,"headers":[
            {
              text: 'UID',
              align: 'start',
              sortable: true,
              value: 'UID',
            },
            {
              text: 'User type',
              align: 'start',
              value: 'userType',
            },
            {
              text: 'Actions',
              align: 'end',
              sortable: false,
              value: 'actions'
            }
          ],"items":_vm.usersList,"items-per-page":5},scopedSlots:_vm._u([{key:`item.userType`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.userType.toString().toUpperCase())+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-container',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editUser(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteUser(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-trash-can ")])],1)],1)]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }