//const config = require('../public/static/js/config');
/* eslint-disable no-undef */
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Vuetify from 'vuetify'
import vuetify from './plugins/vuetify' // path to vuetify export

import axiosApi from 'axios'
import VueAxios from 'vue-axios'

import Notifications from 'vue-notification'

Vue.use(Notifications)
Vue.use(Vuetify)

Vue.config.productionTip = false

// Axios config
var axios = null
if (location.hostname == 'localhost') {
  axios = axiosApi.create({
    baseURL: config.apiBasePath
  });
} else {
  config.apiBasePath = process.env.VUE_APP_BASE_URL
  axios = axiosApi.create({
    baseURL: process.env.VUE_APP_BASE_URL
  });
}

axios.interceptors.request.use(function (aconfig) { // Add a request interceptor
  aconfig.headers['Authorization'] = 'Bearer ' + localStorage.getItem(config.jwtStorageKey);

  return aconfig;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) { // Add a response interceptor

  return response;
}, function (error) {

  if (error.response && error.response.status && [401, 403].indexOf(error.response.status) !== -1) {
    localStorage.setItem(config.jwtStorageKey, 'expired');
    router.push({ name: "homePage" }).catch(() => { });
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

Vue.use(VueAxios, axios)

// Vue init
new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
