<template>
    <v-app>
        <v-container>
            <v-form>
                <v-row>
                    <v-col>
                        <h2>Manage imports {{ currentContainer._id }}</h2>
                    </v-col>
                    <v-col>
                        <v-card-actions>
                            <v-btn color="success" @click='newImportDialog = true'>
                                New
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color='secondary'
                                @click="$router.push({ name: 'importManagement' }).catch(() => { });">Close</v-btn>
                            <v-btn color="success" @click='saveContainer()'>
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols='6'>
                        <v-select :items='Object.keys(imports)' label='Selected import' v-model="selImport"
                            @change="chooseImport()"></v-select>
                    </v-col>
                    <v-col cols='6'>
                        <v-select :items='["AS400", "JSON", "CSV"]' label='Type' v-model="i.type"
                            :disabled="!i.newlyCreated"></v-select>
                    </v-col>
                    <v-alert type="error" v-if="error.length > 0" width="100%">
                        Error: {{ error }}
                    </v-alert>
                    <v-col cols="12">

                        <!-- IMPORT FROM AS400 -->
                        <v-row v-if="i.type === 'AS400'">
                            <v-col cols="12">
                                <v-text-field label="Host" v-model="i.host"></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field label="Connection string"
                                    v-model="i.databaseOdbcConfiguration.connectionString"></v-text-field>
                            </v-col>

                            <v-col cols="2">
                                <v-text-field label="Connection timeout"
                                    v-model="i.databaseOdbcConfiguration.connectionTimeout"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field label="Initial size"
                                    v-model="i.databaseOdbcConfiguration.initialSize"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Increment size"
                                    v-model="i.databaseOdbcConfiguration.incrementSize"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Max size" v-model="i.databaseOdbcConfiguration.maxSize"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-select label="Shrink" v-model="i.databaseOdbcConfiguration.shrink"
                                    :items="[true, false]"></v-select>
                            </v-col>

                            <v-col cols="10">
                                <v-combobox label='Tables' v-model="i.tables" multiple clearable chips></v-combobox>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field label="Max rec" v-model="i.maxrec"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <h3 @click="as400attributesShow = !as400attributesShow"><u>Attributes</u>
                                    <v-icon>{{ as400attributesShow ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </h3>

                                <!-- ATTRIBUTES LIST -->
                                <v-row v-for="a of as400attributes" :key="a.key" v-show="as400attributesShow">
                                    <!-- FIXED -->
                                    <v-col cols="2">
                                        <v-text-field label="Key" v-model="a.key"></v-text-field>
                                    </v-col>
                                    <v-col cols="1">
                                        <v-select label="Type" v-model="a.type" :items="['Col', 'Custom']"></v-select>
                                    </v-col>

                                    <!-- COLUMN TYPE -->
                                    <v-col cols="4" v-if="a.type === 'Col'">
                                        <v-text-field label="Column name" v-model="a.col"></v-text-field>
                                    </v-col>
                                    <v-col cols="2" v-if="a.type === 'Col'">
                                        <v-text-field label="From char" v-model="a.from"></v-text-field>
                                    </v-col>
                                    <v-col cols="3" v-if="a.type === 'Col'">
                                        <v-text-field label="Char length" v-model="a.len"></v-text-field>
                                    </v-col>

                                    <!-- CUSTOM TYPE -->
                                    <v-col cols="9" v-if="a.type === 'Custom'">
                                        <v-text-field label="Free value" v-model="a.value"
                                            :rules="[checkJsonFree(a.value)]"></v-text-field>
                                    </v-col>

                                    <!-- FILL - NO TYPE -->
                                    <v-col cols="8" v-if="!a.type"></v-col>
                                </v-row>

                                <v-btn v-if="as400attributesShow" color="success" @click="as400attributes.push({})">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>


                        <!-- IMPORT FROM JSON -->
                        <v-row v-if="i.type === 'JSON'">
                            <v-col cols="6">
                                <v-text-field label="User UID" v-model="i.userImport.UID"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="User type" v-model="i.userImport.userType"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Folder path" v-model="i.folderPath"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select :items='["key", "json", "nomeFile"]' label='Filename type'
                                    v-model="i.fileName.type"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field ref="fileName.value" label="Filename value" v-model="i.fileName.value"
                                    :rules="[i.fileName.type === 'json' ? checkJsonFree(i.fileName.value) : true]"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Keydoc CM" v-model="i.keydocNameCm"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Version CM" v-model="i.versionNameCm"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Remote file path" v-model="i.remotefilepath"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-select :items='["S", "N"]' label='With file' v-model="i.withFile"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-select :items='[true, false]' label='Phisical delete'
                                    v-model="i.deleteFisica"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-select :items='config.connectionTypes' label='Connection type'
                                    v-model="i.connectionType"></v-select>
                            </v-col>
                        </v-row>


                        <!-- IMPORT FROM CSV -->
                        <v-row v-if="i.type === 'CSV'">
                            <v-col cols="6">
                                <v-text-field label="User UID" v-model="i.userImport.UID"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="User type" v-model="i.userImport.userType"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Folder path" v-model="i.folderPath"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select :items='["key", "json", "nomeFile"]' label='Filename type'
                                    v-model="i.fileName.type"></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field ref="fileName.value" label="Filename value" v-model="i.fileName.value"
                                    :rules="[i.fileName.type === 'json' ? checkJsonFree(i.fileName.value) : true]"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Keydoc CM" v-model="i.keydocNameCm"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Version CM" v-model="i.versionNameCm"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Remote file path" v-model="i.remotefilepath"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-select :items='["S", "N"]' label='With file' v-model="i.withFile"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-select :items='[true, false]' label='Phisical delete'
                                    v-model="i.deleteFisica"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-select :items='config.connectionTypes' label='Connection type'
                                    v-model="i.connectionType"></v-select>
                            </v-col>


                            <!-- JSON BASE TYPE + ADDITIONALS FIELDS -->
                            <v-col cols="12">
                                <v-combobox label='Column headers' v-model="i.colHeaders" multiple clearable
                                    chips></v-combobox>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Separator" v-model="i.separator"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select :items='config.pexTypes' label='Operation type' v-model="i.operation"></v-select>
                            </v-col>
                        </v-row>


                        <!-- COMMON - CONNECTIONCONFIG -->
                        <v-row v-if="i.connectionType && ['FTP', 'SFTP'].includes(i.connectionType)">
                            <v-col cols="12">
                                <h3>Connection config</h3>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Host" v-model="i.connectionConfig.host"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Port" v-model="i.connectionConfig.port"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Username" v-model="i.connectionConfig.username"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="Password" v-model="i.connectionConfig.password"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-select :items='[false, true]' label='Explicit FTPS' v-model="i.secure"></v-select>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field label="Secure options"
                                    v-model="i.connectionConfig.secureOptions"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="i.connectionType && ['S3'].includes(i.connectionType)">
                            <v-col cols="12">
                                <h3>Connection config</h3>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Bucket" v-model="i.connectionConfig.bucket"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Access key" v-model="i.connectionConfig.accesskey"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Access ID" v-model="i.connectionConfig.accessid"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label="Host" v-model="i.connectionConfig.host"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label="Region" v-model="i.connectionConfig.region"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label="Account ID" v-model="i.connectionConfig.account_id"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>

        <v-dialog v-model="newImportDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Add import configuration</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Unique name" required v-model="newImportName"
                                    prefix="importCustom_"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="newImportDialog = false">Cancel</v-btn>
                    <v-btn color="success" @click="newImportConfig()">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-app>
</template>

<script>
const emptyTemplate = {
    userImport: {},
    fileName: {},
    connectionConfig: {},
    databaseOdbcConfiguration: {},
    attribs: {},
}

//const config = require('../../config');
export default {
    name: 'ImportConfigurationDetails',

    data: () => ({
        // eslint-disable-next-line no-undef
        config: config,

        lastSelImport: '',
        selImport: '',

        currentContainer: {}, // Current container data
        imports: {},
        i: {},

        // Specific import config variables
        as400attributes: [], // Attributes array for AS400 import
        as400attributesShow: false,

        // Error feedbacks
        error: '',

        newImportDialog: false,
        newImportName: '',
    }),

    methods: {
        newImportConfig() {
            let _ = this

            let full = 'importCustom_' + _.newImportName
            _.newImportName = ''

            if (!Object.keys(_.imports).includes(full))
                _.imports[full] = { ...emptyTemplate, newlyCreated: true }

            _.newImportDialog = false
        },
        checkJsonFree(val) {
            try {
                JSON.parse(val)
            } catch (error) {
                return false
            }

            return true
        },
        lastItem(array, item) { // Is item last in array
            return array.indexOf(item) === array.length - 1
        },
        fixImport() { // Fix object structure on config load
            let _ = this

            if (_.i.type === 'AS400') {
                let aKeys = Object.keys(_.i.attribs)
                _.as400attributes = []

                for (const key of aKeys) {
                    let a = {
                        key: key,
                        type: _.i.attribs[key].col ? 'Col' : 'Custom',
                    }

                    if (a.type === 'Custom')
                        a.value = JSON.stringify(_.i.attribs[key])
                    else
                        a = { ...a, ..._.i.attribs[key] }

                    _.as400attributes.push(a)
                }

                console.log('Fixed attribs ', _.as400attributes)
            } else {
                if (_.i.fileName.type && _.i.fileName.type.toLowerCase() === 'json')
                    _.i.fileName.value = JSON.stringify(_.i.fileName.value)
            }

        },
        prepSave() { // Fix object structure before config save
            let _ = this

            _.i.newlyCreated = false

            if (_.i.type === 'AS400') {
                _.i.attribs = {}

                for (const attr of _.as400attributes) {
                    if (attr.type === 'Col') {
                        _.i.attribs[attr.key] = {
                            col: attr.col,
                            from: attr.from,
                            len: attr.len,
                        }
                    } else {
                        _.i.attribs[attr.key] = JSON.parse(attr.value)
                    }
                }
            } else {
                console.log('Fixing JSON', _.i.fileName.value)
                if (_.i.fileName.type && _.i.fileName.type.toLowerCase() === 'json')
                    _.i.fileName.value = JSON.parse(_.i.fileName.value)
            }
        },
        chooseImport() {
            let _ = this

            if (_.lastSelImport) {
                _.prepSave() // Prepare for save

                _.imports[this.lastSelImport] = { ..._.i } // Reassign in parent object
                console.log('Saved importConfig', this.lastSelImport)
            }

            _.lastSelImport = _.selImport // Update new importConfig selected name
            _.i = _.imports[this.selImport] // Load selected importConfig

            _.as400attributes = []
            _.as400attributesShow = false

            _.fixImport() // Fix imported config
        },
        isImport(key) {
            return key === 'importConfig' || key.includes('importCustom_')
        },
        saveContainer() { // Insert or update container data
            let _ = this

            _.prepSave() // Prepare for save

            _.imports[this.lastSelImport] = { ..._.i } // Reassign in parent object
            console.log('Saved for API', this.lastSelImport)

            this.error = '';

            let keys = Object.keys(_.imports)
            for (const key of keys) {
                let i = _.imports[key]
                _.currentContainer[key] = { ...i }
            }

            this.axios
                .post('/mng/insert/containers', this.currentContainer).then(() => {
                    this.$router.push({ name: 'importManagement' }).catch(() => { });
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })

        },
    },

    mounted: function () {
        var _id = this.$route.params.id; // Get id from url
        let _ = this

        this.axios
            .post(`/mng/read/containers`, { _id: _id }).then(response => {

                this.currentContainer = { ...response.data.data }[0];

                let keys = Object.keys(_.currentContainer)
                for (const key of keys) {
                    if (_.isImport(key)) {

                        let i = _.currentContainer[key]
                        _.imports[key] = { ...i }
                    }
                }

                // Autoload first importConfig
                this.selImport = Object.keys(_.imports)[0]
                this.lastSelImport = this.selImport

                _.i = _.imports[this.selImport]

                _.fixImport()

            }).catch((err) => {
                console.error(err)
                this.$notify({
                    group: 'main',
                    type: 'error',
                    title: 'Error ' + err.response.data.msg[0].msgId,
                    text: err.response.data.msg[0].msgTxt
                })
            });

    }
};

</script>