<template>
  <v-app style="background-color: #dedede">
    <v-container fill-height class="justify-center">
      <v-card width="400px" class="pa-5">
        <div class="text-h4 text-center">ContentManager</div>
        <div class="text-h5 text-center">Login</div>

        <v-select label="User type" hide-details="auto" :items="loginTypes" item-value="authType" item-text="description"
          class="mt-6" v-model="userType" :loading="loadingPortals">
        </v-select>

        <v-text-field label="Username" hide-details="auto" v-model="username" class="mt-4" :disabled="loadingPortals">
        </v-text-field>

        <v-text-field label="Password" hide-details="auto" type="password" v-model="password" class="mt-4"
          :disabled="loadingPortals">
        </v-text-field>

        <v-alert type="error" v-if="loginError" class="mt-8">
          {{ loginErrorText }}
        </v-alert>

        <v-card-actions class="justify-center mt-5">
          <v-btn :loading="loading" color="success" @click="login()" :disabled="loadingPortals"> Login </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
</v-app>
</template>

<script>
//const config = require("../config");
export default {
  name: "LoginPage",

  data: () => ({
    username: "",
    password: "",
    userType: "",
    loginTypes: [],

    loginErrorText: "",
    loginError: false,

    loading: false,
    loadingPortals: false,
  }),
  mounted() {
    let _ = this

    this.loadingPortals = true

    let payload = {
      // eslint-disable-next-line no-undef
      portal: config.portal,
    }

    _.axios
      .post("/listAuth", payload, { headers: { Authorization: '' } })
      .then((result) => {
        _.loginTypes = result.data.data

        this.loadingPortals = false
      }).catch((err) => {
        console.error(err)
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error ' + err.response.data.msg[0].msgId,
          text: err.response.data.msg[0].msgTxt
        })

        this.loadingPortals = false
      })
  },
  methods: {
    login() {
      let _ = this;
      _.loginErrorText = "";
      _.loginError = false;
      if (_.username.length === 0) {
        _.loginErrorText = "Missing User";
        _.loginError = true;
        return;
      }
      if (_.password.length === 0) {
        _.loginErrorText = "Missing Password";
        _.loginError = true;
        return;
      }
      _.loading = true;
      var payload = {
        username: _.username.toUpperCase(),
        password: _.password,
        userType: _.userType,
      };
      _.axios
        .post("/auth/login", payload, { headers: { Authorization: '' } })
        .then((result) => {
          localStorage.setItem("AWS_USER", _.username);
          // eslint-disable-next-line no-undef
          config.AWS_USER = _.username;
          for (const token of result.data.data) {
            localStorage.setItem(token.name, token.value);
            // eslint-disable-next-line no-undef
            config[token.name] = token.value;
          }
          // eslint-disable-next-line no-undef
          config.authSuccessful = true;
          _.loading = false;
          _.$emit("loggedIn", true);
        })
        .catch((err) => {
          _.loading = false;
          _.loginError = true;
          _.loginErrorText = "An error occurred in login";

          console.error(err)
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error ' + err.response.data.msg[0].msgId,
            text: err.response.data.msg[0].msgTxt
          })

        });
    },
  },
};
</script>