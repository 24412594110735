<template>
  <v-app>

    <v-container>
      <UserManagementDetails v-if="showDetails" :inputUser="selectedUser" :inputIsNew="showDetailsIsNew"
        @closed="showDetails = false; getUsersList();"></UserManagementDetails>

      <h2>User management</h2>

      <v-row class="justify-space-between">
        <v-col cols="11">
          <v-text-field label='Search user' required v-model='searchText' @input="getUsersList()"></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn @click="addUser()" color="success" class="mt-2 mb-2" style="float: right">
            <v-icon>
              mdi-plus-box
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table class="elevation-3" :search="searchText" :headers="[
            {
              text: 'UID',
              align: 'start',
              sortable: true,
              value: 'UID',
            },
            {
              text: 'User type',
              align: 'start',
              value: 'userType',
            },
            {
              text: 'Actions',
              align: 'end',
              sortable: false,
              value: 'actions'
            }
          ]" :items="usersList" :items-per-page="5">

            <template v-slot:[`item.userType`]="{ item }">
              <!-- Formatted user type -->

              {{ item.userType.toString().toUpperCase() }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <!-- Actions for every row -->

              <v-container class="d-flex flex-row-reverse">
                <v-btn @click="editUser(item)" class="ml-2" color="primary">
                  <v-icon color="white">
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn @click="deleteUser(item)" color="red">
                  <v-icon color="white">
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </v-container>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

</v-app>
</template>

<script>
import UserManagementDetails from './UserManagementDetails.vue'

export default {
  name: "UserManagement",

  data: () => ({
    usersList: [], // Users list
    selectedUser: {}, // Selected user data

    searchText: '', // Text to search (service side)

    showDetails: false,
    showDetailsIsNew: false,
  }),

  mounted() { // Get all users
    this.axios
      .post(`/mng/read/login`, { searchParam: this.searchText }).then(response => {
        this.usersList = response.data.data;
      }).catch((err) => {
        console.error(err)
        this.$notify({
          group: 'main',
          type: 'error',
          title: 'Error ' + err.response.data.msg[0].msgId,
          text: err.response.data.msg[0].msgTxt
        })
      })
  },

  methods: {

    editUser(user) { // Show modal for user edit
      this.selectedUser = { ...user };
      this.showDetailsIsNew = false;

      this.showDetails = true;
    },

    deleteUser(user) { // Delete existing user
      this.axios
        .post("/mng/delete/login", { // Send delete request
          _id: user._id,
        }).then(() => { // Refresh users list
          this.axios
            .post("/mng/read/login", {}).then(response => {
              this.usersList = response.data.data;
            })
        }).catch((err) => {
          console.error(err)
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error ' + err.response.data.msg[0].msgId,
            text: err.response.data.msg[0].msgTxt
          })
        });
    },

    addUser() { // Show modal for user creation
      this.selectedUser = {};
      this.showDetailsIsNew = true;

      this.showDetails = true;
    },

    getUsersList() { // Refresh users list
      this.axios
        .post(`/mng/read/login`, { searchParam: this.searchText }).then(response => {
          this.usersList = response.data.data;
        }).catch((err) => {
          console.error(err)
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error ' + err.response.data.msg[0].msgId,
            text: err.response.data.msg[0].msgTxt
          })
        })
    }
  },

  components: { UserManagementDetails }
};

</script>