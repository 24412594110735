var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',[_c('h2',[_vm._v("Filter list")]),_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"label":"Search filter","required":""},on:{"input":function($event){return _vm.getFiltersList()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-2 mb-2",staticStyle:{"float":"right"},attrs:{"color":"success"},on:{"click":function($event){return _vm.addFilter()}}},[_c('v-icon',[_vm._v(" mdi-plus-box ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":[
                        {
                            text: 'Filter ID',
                            align: 'start',
                            sortable: true,
                            value: '_id',
                        },
                        {
                            text: 'Filter name',
                            align: 'start',
                            value: 'nome',
                        },
                        {
                            text: 'Filter description',
                            align: 'start',
                            value: 'desc.it_IT',
                        },
                        {
                            text: 'Actions',
                            align: 'end',
                            sortable: false,
                            value: 'actions'
                        }
                    ],"items":_vm.filtersList,"items-per-page":5},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-container',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editFilter(item)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-eye ")])],1)],1)]}}],null,true)})],1)],1)],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }