<template>
    <v-app>

        <v-container>

            <h2>Filter list</h2>

            <v-row class="justify-space-between">
                <v-col cols="11">
                    <v-text-field label='Search filter' required v-model='searchText' @input="getFiltersList()">
                    </v-text-field>
                </v-col>
                <v-col cols="1">
                    <v-btn @click="addFilter()" color="success" class="mt-2 mb-2" style="float: right">
                        <v-icon>
                            mdi-plus-box
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-data-table class="elevation-3" :headers="[
                        {
                            text: 'Filter ID',
                            align: 'start',
                            sortable: true,
                            value: '_id',
                        },
                        {
                            text: 'Filter name',
                            align: 'start',
                            value: 'nome',
                        },
                        {
                            text: 'Filter description',
                            align: 'start',
                            value: 'desc.it_IT',
                        },
                        {
                            text: 'Actions',
                            align: 'end',
                            sortable: false,
                            value: 'actions'
                        }
                    ]" :items="filtersList" :items-per-page="5">

                        <template v-slot:[`item.actions`]="{ item }">
                            <!-- Actions for every row -->

                            <v-container class="d-flex flex-row-reverse">
                                <v-btn @click="editFilter(item)" class="ml-2" color="primary">
                                    <v-icon color="white">
                                        mdi-eye
                                    </v-icon>
                                </v-btn>
                            </v-container>
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>

        <router-view />
</v-app>
</template>

<script>

export default {
    name: "FilterList",

    data: () => ({
        filtersList: [], // Current filter list

        searchText: '', // Text to search (service side)
    }),

    mounted() { // Get all filters
        this.axios
            .post(`/mng/list/filters`, { searchParam: this.searchText }).then(response => {
                this.filtersList = response.data.data;
            })
    },

    methods: {

        editFilter(filter) { // Open page for filter edit
            this.$router.push({ name: `filterManagement_id`, params: { id: filter._id } });
        },

        addFilter() { // Open page for filter creation
            this.$router.push({ name: `filterManagement_id`, params: { id: 'new' } });
        },

        getFiltersList() { // Refresh filter list
            this.axios
                .post(`/mng/list/filters`, { searchParam: this.searchText }).then(response => {
                    this.filtersList = response.data.data;
                }).catch((err) => {
                    console.error(err)
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: 'Error ' + err.response.data.msg[0].msgId,
                        text: err.response.data.msg[0].msgTxt
                    })
                })
        }
    },
};

</script>